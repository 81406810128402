import { useState, useRef } from "react";
import CardPortal from "./components/CardPortal";
import CardSosial from "./components/CardSosial";
import ReactPlayer from "react-player";
import { Icon } from '@iconify/react';

function App() {
  const bottomEl = useRef(null);
  const [showButton, setShowButton] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);

  const toggleButton = () => {
    setPlayVideo(showButton);
    setShowButton(!showButton);
  };

  const togglePause = () => {
    setShowButton(true);
  }

  const togglePlay = () => {
    setShowButton(false);
  }

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <div className="relative object-cover flex items-center justify-center h-screen w-screen overflow-hidden">
        <ReactPlayer 
          controls={true}
          url="/video/video.mp4" 
          playing={playVideo}
          loop={true}
          light={false}
          muted={false}
          onPause={togglePause}
          onPlay={togglePlay}
          className="absolute z-10 min-w-full min-h-full"
        />
        {showButton && 
        <button className="relative z-30 p-5 rounded-full bg-slate-500 bg-opacity-50 ring-2 ring-stone-50 hover:bg-slate-500 hover:ring-stone-100" onClick={toggleButton}>
          <Icon icon="fa6-solid:play" width="32" height="32" color="white" />
        </button>
        }
        <button className="absolute z-30 bottom-12 text-white font-bold p-5 rounded-full bg-ungu-800 bg-opacity-50 ring-2 ring-stone-50 hover:bg-ungu-800 hover:ring-stone-100" onClick={scrollToBottom}>
          Portal KISS
        </button>
      </div>
      <div
        className="min-h-screen w-full bg-gradient-to-b from-ungu-900 to-purple-500"
      >
        <div className="min-h-screen w-full p-6 absolute" />
        <div className="flex flex-wrap justify-center relative">
          <div className="grid grid-cols-1 relative">
            <div className="flex justify-center">
              <img
                src="/img/pemprov.png"
                alt="Pemprov Kaltim"
                className="h-32 mt-10 mb-6"
              />
            </div>
            <div className="flex justify-center">
              <h1 className="sm:text-2xl font-bold text-center text-white uppercase">
                Badan Pengembangan Sumber Daya Manusia
              </h1>
            </div>
            <div className="flex justify-center">
              <h2 className="sm:text-2xl font-bold text-center text-white uppercase">
                Provinsi Kalimantan Timur
              </h2>
            </div>
            <div className="flex justify-center">
              <h1 className="sm:text-3xl pt-10 font-bold text-center text-white uppercase">
                Knowledge Integrated Solution System
              </h1>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center mt-10 sm:mx-6 md:mx-6 2xl:mx-72 opacity-100 relative">
          <CardPortal
            title="Website"
            tooltip="Website Utama"
            href="https://bpsdm.kaltimprov.go.id/web"
            img="/icon/icons8-website-100.png"
            id="tipWebsite"
          />
          <CardPortal
            title="SIMPel"
            tooltip="Sistem Informasi Manajemen Pelatihan"
            href="https://simpel.kaltimprov.go.id"
            img="/icon/icons8-library-100.png"
            id="tipSimpel"
          />
          <CardPortal
            title="LC"
            tooltip="Learning Center"
            href="https://elearning.kaltimprov.go.id"
            img="/icon/icons8-comics-magazine-100.png"
            id="tipLC"
          />
          <CardPortal
            title="Dinov"
            tooltip="Database Inovasi"
            href="https://bpsdm.kaltimprov.go.id/apps/dinov"
            img="/icon/icons8-idea-100.png"
            id="tipDinov"
          />
          <CardPortal
            title="CBT"
            tooltip="Computer Based Test"
            href="https://cbt.bpsdmkaltim.net"
            img="/icon/icons8-shortlist-100.png"
            id="tipCBT"
          />
          <CardPortal
            title="EvOn"
            tooltip="Evaluasi Online"
            href="/"
            img="/icon/icons8-facebook-like-100.png"
            id="tipEvOn"
          />
          <CardPortal
            title="DigiLib"
            tooltip="Digital Library"
            href="/"
            img="/icon/icons8-course-100.png"
            id="tipDigiLib"
          />
          <CardPortal
            title="PiAwan"
            tooltip="Penyimpanan Awan"
            href="http://bpsdmkaltim.quickconnect.to/"
            img="/icon/icons8-cloud-sync-100.png"
            id="tipPiAwan"
          />
          <CardPortal
            title="AKPK"
            tooltip="Analisis Kebutuhan Pengembangan Kompetensi"
            href="https://akpk.kaltimprov.go.id"
            img="/icon/icons8-adventures-100.png"
            id="tipAKPK"
          />
          <CardPortal
            title="SiMonBangKom"
            tooltip="Sistem Monitoring Pengembangan Kompetensi"
            href="https://simonbangkom.kaltimprov.go.id"
            img="/icon/icons8-historical-100.png"
            id="tipSimonBangkom"
          />
          <CardPortal
            title="NIJ"
            tooltip="Nusantara Innovation Journal"
            href="https://nij.kaltimprov.go.id"
            img="/icon/icons8-product-documents-100.png"
            id="tipNIJ"
          />
          <CardPortal
            title="KMS"
            tooltip="Knowledge Management System"
            href="https://kms.kaltimprov.go.id"
            img="/icon/icons8-learning-100.png"
            id="tipKMS"
          />
          <CardPortal
            title="PPID"
            tooltip="Portal PPID Pelaksana"
            href="https://ppid.bpsdm.kaltimprov.go.id"
            img="/icon/icons8-about-100.png"
            id="tipPPID"
          />
          <CardPortal
            title="Statistik"
            tooltip="Data Statistik"
            href="/"
            img="/icon/icons8-combo-chart-100.png"
            id="tipStat"
          />
          <CardPortal
            title="Bantuan"
            tooltip="Bantuan Online"
            href="https://help.bpsdmkaltim.net"
            img="/icon/icons8-support-100.png"
            id="tipBantuan"
          />
          <CardPortal
            title="Pengaduan"
            tooltip="SP4N LAPOR!"
            href="https://www.lapor.go.id"
            img="/icon/lapor-100.png"
            id="tipPengaduan"
          />
        </div>
        <footer className="text-xs text-gray-600 font-semibold text-center inset-x-0 bottom-0 py-10">
          <div className="flex flex-wrap justify-center sm:mx-6 md:mx-6 xl:mx-72 relative">
            <CardSosial
              title="Instagram"
              tooltip="@bpsdmkaltim"
              href="https://www.instagram.com/bpsdmkaltim"
              img="/icon/icons8-instagram-100.png"
              id="tipIg"
            />
            <CardSosial
              title="Facebook"
              tooltip="BPSDM Provinsi Kaltim"
              href="https://www.facebook.com/bpsdmprovkaltim"
              img="/icon/icons8-facebook-100.png"
              id="tipFB"
            />
            <CardSosial
              title="Twitter"
              tooltip="@bpsdmkaltim"
              href="https://twitter.com/bpsdmkaltim"
              img="/icon/icons8-twitter-100.png"
              id="tipTwitter"
            />
            <CardSosial
              title="Youtube"
              tooltip="BPSDM Kaltim"
              href="https://www.youtube.com/channel/UCLrwXs0jNQ6dMjpDSXlc--w"
              img="/icon/icons8-youtube-squared-100.png"
              id="tipYT"
            />
          </div>
          <div className="mt-6 text-white">
            Hak Cipta © {new Date().getFullYear()} - Badan Pengembangan Sumber
            Daya Manusia Provinsi Kalimantan Timur
          </div>
          <div ref={bottomEl}></div>
        </footer>
      </div>
    </>
  );
}

export default App;
